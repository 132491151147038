angular.module('fingerink')
    .controller('leftnavabarCtrl', function ($scope, $state, $stateParams, session, uploadDocumentModal) {

        $scope.controller = this;
        var that = this;

        $scope.$on("sessionChanged", () => {
            that.user = session.get().user;
        });


        that.uploadDocumentModal = ()=> uploadDocumentModal.openModal();
        that.user = session.get().user;

        $scope.state = $state;
        $scope.stateParams = $stateParams;

    });